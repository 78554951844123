<route>
{
  "meta": {
    "permission": [
      "packages.view_payment"
    ]
  }
}
</route>

<template>
  <div>
    <dialpayment v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('payment', 2)"
      :headers="headers"
      api="subDomain.payment"
      :reload.sync="reload"
      :otherPermission="isAdmin"
      dontCreate
      dontRemove
      @click:edit="isAdmin ? open($event) : ''"
    >
      <template v-slot:item.approved="{ item }">
        <i-btn
          :title="$tc('approved', 1)"
          classes="mx-1"
          outlined
          class="mx-2 mb-1"
          :color="item.approved ? 'secondary' : 'red'"
          :icon="item.approved ? 'fa-check' : 'fa-times'"
        />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialpayment from '../../components/subDomain/payment.vue'
export default {
  components: {
    dialpayment
  },
  data() {
    return {
      dialog: false,
      toEdit: null,
      reload: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'session/isAdmin'
    }),
    headers() {
      return [
        {
          text: this.$t('payment_number'),
          value: 'payment_number'
        },
        {
          text: this.$t('state'),
          value: 'state'
        },
        {
          text: this.$tc('amount', 1),
          value: 'amount'
        },
        {
          text: this.$t('payment_type'),
          value: 'payment_type'
        },
        {
          text: this.$t('date_pay'),
          value: 'date_pay'
        },
        {
          text: this.$tc('user', 1),
          value: 'user.username'
        },
        {
          text: this.$tc('package', 1),
          value: 'package.name'
        },
        {
          text: this.$tc('subdomain', 1),
          value: 'subdomain.domain_url'
        },
        {
          text: this.$t('approved'),
          value: 'approved'
        },
        {
          text: this.isAdmin ? this.$t('action') : '',
          value: this.isAdmin ? 'actions' : ''
        }
      ]
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
