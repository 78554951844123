<template>
  <i-modal
    :title="$t('editing', { value: $tc('payment', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row style="display: inline-flex" align="center" justify="center">
      <v-col v-if="payment != undefined">
        <v-switch v-model="payment.approved" :label="$t('approved')"></v-switch>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      payment: {
        id: '',
        payment_number: '',
        amount: 0,
        approved: false,
        date_pay: '',
        package: null,
        payment_number: '',
        payment_type: '',
        state: '',
        subdomain: null,
        user: null
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.payment = this.toEdit
        }
      }
    }
  },
  methods: {
    async submit() {
      this.$api.subDomain.payment
        .edit({
          pk: this.payment.id,
          form: this.payment
        })
        .then((response) => {
          if (response.status == 200 && response.statusText == 'OK') {
            this.$emit('done')
            this.$emit('input', false)
            this.$toast.success(
              `${this.$tc('payment', 1)} ${this.$tc('edited', 2)}`
            )
          }
        })
    }
  }
}
</script>
